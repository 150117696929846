import React, { Fragment, useEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Collapse, ListItemButton, Tooltip } from '@mui/material';
import getPathname from "../../Util/pathname";
// Icons
import { ReactComponent as BinocularsIcon } from "../../assets/images/binoculars-icon.svg";
import { ReactComponent as ChartPlantIcon } from "../../assets/images/chart-plant-icon.svg";
import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown.svg';
import { ReactComponent as BookIcon } from "../../assets/images/book.svg";
import { ReactComponent as CoverageIcon } from "../../assets/images/coverage.svg";
import { ReactComponent as AnalyticIcon } from '../../assets/images/analytic.svg';
import { ReactComponent as QuoteIcon } from '../../assets/images/quotes.svg';
import { ReactComponent as PersonIcon } from "../../assets/images/person.svg";
import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/images/policies.svg';
import { ReactComponent as EventsIcon } from '../../assets/images/events.svg';
import clsx from 'clsx';

export const ROLE = {
  Agent: "User.Agent",
  Admin: "User.Admin",
  Underwriter: "User.Underwriter"
}

const SIDEBAR_ITEMS = [
  {
    title: "BUILD",
    key: "build",
    children: [
      {
        title: "Data Catalog",
        tooltip: "Explore and manage datasets and data catalogs. Create and activate parametric coverages based on available data.",
        icon: <BookIcon />,
        path: '/data-catalog',
        allowedRoles: [ROLE.Underwriter, ROLE.Agent, ROLE.Admin],
        moduleKey: "dataCatalog"
      },
      {
        title: "Data Explorer",
        tooltip: "View and manage events and their details. Utilize the monitoring tools for effective operational oversight.",
        icon: <DashboardIcon />,
        path: "/data-explorer",
        allowedRoles: [ROLE.Underwriter, ROLE.Admin],
        moduleKey: "xcube"
      },
      // {
      //   title: "Experiments",
      //   icon: null,
      // },
      {
        title: "Coverages",
        tooltip: "List and manage insurance coverages. Group coverages by monitoring targets. Search for coverages by full-text and monitoring targets.",
        icon: <CoverageIcon />,
        path: '/coverages',
        allowedRoles: [ROLE.Underwriter, ROLE.Agent, ROLE.Admin],
        moduleKey: "coverages"
      },
      {
        title: "Analytics",
        icon: <AnalyticIcon />,
        path: '/analytics',
        allowedRoles: [ROLE.Underwriter],
        moduleKey: "analytics"
      },
    ],
  },
  {
    title: "DISTRIBUTE",
    key: "distribute",
    children: [
      {
        title: "Dashboard",
        icon: <DashboardIcon />,
        path: '/dashboard',
        allowedRoles: [ROLE.Underwriter, ROLE.Agent, ROLE.Admin],
        moduleKey: "dashboard"
      },
      {
        title: "Quotes",
        tooltip: "Manage insurance quotes efficiently. List, create, and track quotes. Access detailed information about quote states.",
        icon: <QuoteIcon />,
        path: '/quotations',
        allowedRoles: [ROLE.Agent],
        moduleKey: "quotations"
      },
      {
        title: "Programs",
        tooltip: "List and create insurance programs. Access program details and configurations for effective distribution.",
        icon: <ChartPlantIcon />,
        path: '/programs',
        allowedRoles: [ROLE.Underwriter, ROLE.Agent],
        moduleKey: "programs"
      },
      {
        title: "Business partners",
        tooltip: "Access business partner details. Create and manage business partners for streamlined distribution processes.",
        icon: <PersonIcon />,
        path: "/clients",
        allowedRoles: [ROLE.Agent],
        moduleKey: "clients"
      },
    ],
  },
  {
    title: "OPERATE",
    key: "operate",
    children: [
      {
        title: "Policies",
        tooltip: "Create, edit, and manage insurance policies. Access policy-related reports and information for operational control.",
        icon: <PoliciesIcon />,
        path: "/policies",
        allowedRoles: [ROLE.Admin],
        moduleKey: "policies"
      },
      // {
      //   title: "Claims",
      //   icon: null,
      // },
      {
        title: "Events",
        tooltip: "Find and manage events using a map. Explore details and status of ongoing or past events for operational insights.",
        icon: <EventsIcon />,
        path: "/events",
        allowedRoles: [ROLE.Admin],
        moduleKey: "events"
      },
      {
        title: "Monitoring",
        tooltip: "View and manage events and their details. Utilize the monitoring tools for effective operational oversight.",
        icon: <BinocularsIcon />,
        path: "/monitoring",
        allowedRoles: [ROLE.Underwriter, ROLE.Admin],
        moduleKey: "monitoring"
      }
    ],
  },
];

const Aside = (props) => {
  const theme = useTheme();
  const drawerWidth = theme.drawerWidth;
  const [isOpen, setIsOpen] = useState({
    build: true,
    distribute: true, 
    operate: true, 
  });
  const [activePath, setActivePath] = useState("");

  const useStyles = useMemo(
    () =>
      makeStyles({
        icon: {
          color: "inherit",
        },
        drawer: {
          width: drawerWidth,
          flexShrink: 0,
        },
        drawerPaper: {
          border: 0,
          width: drawerWidth,
          boxShadow: "4px 0px 12px rgba(0, 0, 0, 0.25)",
        },
        drawerContainer: {
          overflow: "auto",
        },
        rootTitle: {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 700,
          color: "#0000008A",
        },
        listItem: {
          padding: "4px 16px",
        },
        listItemIcon: {
          minWidth: "unset",
          width: "32px",
          color: "#0000008A",
        },
        listItemTitle: {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 400,
          color: "#0000008A",
        },
        listItemActive: {
          color: theme.palette.text.primary,

          '& svg path': {
            fill: theme.palette.text.primary,
            fillOpacity: 1,
          },
        },
        popper: {
          pointerEvents: 'none',       
        }
      }),
    [drawerWidth, theme],
  );

  const classes = useStyles();

  const handleMenuClick = (key) => {
    setIsOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  useEffect(() => {
    const pathname = getPathname();

    // Get first level of URI, for example:
    // "/coverages/new" ==> "coverages"
    const urlFirstLevel = pathname.split("/")[1];
    setActivePath('/' + urlFirstLevel);
  }, []);

  const items = useMemo(
    () =>
      SIDEBAR_ITEMS.filter((root) =>
        root.children.some(
          (item) => {
            return (
              (item.allowedRoles.some((role) => props.roles.includes(role)) &&
              props.configs?.[item.moduleKey])
            );
          },
        ),
      ).map((root) => {
        return (
          <Fragment key={root.key}>
            <ListItemButton onClick={() => handleMenuClick(root.key)}>
              <ListItemText
                primary={root.title}
                primaryTypographyProps={{ className: classes.rootTitle }}
              />
              <Box
                component={DropdownIcon}
                sx={{
                  ...(isOpen[root.key] ? { transform: "rotate(180deg)" } : {}),
                  transition: "0.3s ease-in-out",
                }}
              />
            </ListItemButton>
            <Collapse in={isOpen[root.key]} unmountOnExit>
              <List component="div" disablePadding>
                {root.children.map((item) => {
                  const hasAccess = (item.allowedRoles.some(role => props.roles.includes(role)) && props.configs?.[item.moduleKey]);
                  const isActive = activePath === item.path ;

                  return hasAccess ? (
                    <Tooltip title={item.tooltip} classes={{ popper: classes.popper }} key={item.path}>
                      <ListItem
                        component={RouterLink}
                        sx={{ pl: 4 }}
                        to={item.path}
                        classes={{ root: classes.listItem }}
                        onClick={() => setActivePath(item.path)}
                      >
                        <ListItemIcon
                          classes={{
                            root: clsx(
                              classes.listItemIcon,
                              isActive ? classes.listItemActive : "",
                            ),
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          primaryTypographyProps={{
                            className: clsx(
                              classes.listItemTitle,
                              isActive ? classes.listItemActive : "",
                            ),
                          }}
                        />
                      </ListItem>
                    </Tooltip>
                  ) : null;
                })}
              </List>
            </Collapse>
          </Fragment>
        );
      }),
    [activePath, classes, isOpen, props.configs, props.roles],
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.drawerIsOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List component="nav" aria-label="main navigation">
        {items}
      </List>
    </Drawer>
  );
};

export default Aside;
